.deptLabel {
  font-size: 20px;
}

.deptDate {
  width: 140px;
}

.deptSelect {
  width: 150px;
}

.deptBtn {
  width: 200px;
}
