.row-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .row-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.col-grid {
  transition: all 0.5s;
  min-height: 252px;
  position: relative;
  display: grid;
}

.col-grid.edit {
  z-index: 10;
  grid-row: 2 span;
}

.col-grid-sm {
  transition: all 0.5s;
  position: relative;
  display: grid;
}

#address_form .badge-coupon {
  box-shadow: 0px 0px 0px 1px #60513d;
  text-shadow: 1px #60513d;
  padding: 0.4em 0.9em;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 3px;
  padding-top: 5px;
  transition: all 0.2s linear;
}

#address_form .badge-coupon i {
  font-weight: 500;
  font-size: 0.75em;
}

#address_form .badge-coupon:hover {
  text-shadow: 3px #60513d;
  box-shadow: 0px 0px 0px 3px #60513d;
}

#address_form .fa-times-circle {
  display: none;
}

#address_form .reload-template .fa-times-circle {
  display: initial !important;
}

#address_form .badge-coupon .fa-times-circle {
  position: absolute;
  top: -1em;
  right: -1em;
}

#address_form .badge-coupon .fa-times-circle::before {
  font-size: 14px;
  background: white;
}
