.dashBtn {
  width: 50%;
  background-color: #51bcda !important;
}

.dispatchText {
  font-size: 14px;
}

.dashBtn:hover {
  background-color: #2ba9cd !important;
}

.scanBtn {
  width: 100%;
  background-color: #51bcda !important;
}

.selectAll {
  width: 100%;
  background-color: #4681f4;
}

.receivedBtn {
  width: 100%;
  background-color: goldenrod !important;
}

.dropOffBtn {
  width: 100%;
  background-color: green !important;
}

.scanBtn:hover {
  background-color: #2ba9cd !important;
}

$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
$card-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$card-shadow-hover: 0 14px 28px rgba(0, 0, 0, 0.25),
  0 10px 10px rgba(0, 0, 0, 0.22);

.packageCard {
  font-size: 15px;
  font-weight: bold;
  transition: $transition;
  box-shadow: $card-shadow;
  background: #fff;
  margin: 20px 10px;
  cursor: pointer;
  &:hover {
    box-shadow: $card-shadow-hover;
  }
}

.selected {
  border: 5px solid #4681f4 !important;
  border-top: 5px solid #4681f4 !important;
  border-right: 5px solid #4681f4 !important;
  border-bottom: 5px solid #4681f4 !important;
}

.tab-switch {
  padding: 8px;
  color: white;
  border: solid 1px white;
}

.tab-switch.active {
  background-color: green;
  border: solid 1px green;
}

.dispatchTitle {
  border: 1px solid #2ba9cd;
}

.packageScroll {
  overflow: auto;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 0;
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 390px;
}
