.react-datepicker__input-container {
  height: 100%;
}

.btn-outline-filter {
  border: 2px solid #51cbce;
  color: white;
  background-color: transparent;
}

.btn-outline-filter.selected {
  border: 2px solid #51cbce;
  color: white;
  background-color: darkcyan;
}

.card-wizard {
  background: #27293d;
  color: white;
}

.pdf-white * {
  color: black !important;
}

/* .pdf-white .table-bordered, */
/* .pdf-white .table-bordered tr:not(:last-child) td:not(:last-child) {
  border-top: 1px solid black !important;
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}
.pdf-white .table-bordered tr:not(:last-child) td:last-child {
  border-top: 1px solid black !important;
  border-right: 1px solid black !important;
}

.pdf-white .table-bordered tr:last-child td {
  border: 1px solid black !important;
} */
