.searchBar {
  color: white !important;
}

.searchBar::placeholder {
  color: white !important;
}

.whiteButton {
  background-color: transparent;
  border: 1px solid white;
  color: white;
}

.whiteButton:hover {
  background-color: transparent;
  border: 1px solid #5ac8fa;
  color: #5ac8fa !important;
}

.delButton {
  background-color: transparent;
  border: 1px solid red;
  color: red;
  position: relative;
  bottom: 10px;
}

.delButton:hover {
  background-color: transparent;
  border: 1px solid red;
  color: red !important;
}

.remarksInput {
  font-size: 15px;
}

.feedbackTabs {
  color: white !important;
  cursor: pointer;
}

.isActive {
  background-color: green !important;
  color: white !important;
}
