.mScrollbar {
  overflow: auto;
  /* position: absolute; */
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 670px;
}
.react-datepicker__input-container {
  height: 100%;
}

.btn-outline-filter {
  border: 2px solid #51cbce;
  color: white;
  background-color: transparent;
}

.btn-outline-filter.selected {
  border: 2px solid #51cbce;
  color: white;
  background-color: darkcyan;
}
.selected {
  border: 5px solid #dddfe3;
}
