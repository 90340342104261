.website-order {
  border-radius: 5px;
  background-color: lightskyblue;
  overflow-wrap: break-word;
}
.ecommerce-order {
  border-radius: 5px;
  background-color: #51cbce;
  overflow-wrap: break-word;
}
.avg-order {
  border-radius: 5px;
  background-color: rebeccapurple;
  overflow-wrap: break-word;
}
.new-sales {
  border-radius: 5px;
  background-color: orange;
  overflow-wrap: break-word;
}
.category-sales {
  border-radius: 5px;
  border: 5px solid orange;
  overflow-wrap: break-word;
}
