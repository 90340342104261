.disabled-tr {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.disabled-tr span {
  color: white;
  font-weight: bolder;
  font-size: larger;
}

.stock-detail .page-link {
  font-size: xx-large;
  height: 100%;
}
