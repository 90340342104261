.bannerScroll {
  overflow: auto;
  /* position: absolute; */
  /* left: 10px;  */
  top: 10px;
  bottom: 10px;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 650px;
}

.tab-switch {
  padding: 5px;
  color: white;
  border: solid 1px white;
}

.tab-switch.active {
  background-color: green;
  border: solid 1px green;
}

.addBtn {
  background-color: #51bcda;
  margin-bottom: 0px;
}

.viewBtn {
  width: 152px;
  height: 35px;
}

.rankBtn {
  width: 150px;
  height: 36px;
  padding-bottom: 26px;
}

.closeBtn {
  background-color: transparent !important;
  color: black;
  font-size: 20px;
}

.closeBtn:focus {
  background-color: transparent !important;
  color: black !important;
}

.closeBtn:hover {
  background-color: transparent !important;
  color: gray !important;
}

.modalContainer {
  border: 1px solid black;
}

.modalInput {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 350px;
}

.modalDate {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 150px;
}

.modalCheck {
  margin-top: 25px;
  margin-bottom: 15px;
  transform: scale(1.7);
}

.modalLabel {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 19px;
}

.bannerImg {
  width: 300px;
}

.removeImg {
  left: 305px;
  top: -5px;
  position: absolute;
  border: 0px !important;
  background-color: transparent !important;
  z-index: 5;
}

.removeIcon {
  background-color: transparent !important;
  color: red;
}

.removeBanner {
  right: 5px;
  top: -10px;
  position: absolute;
  border: 0px !important;
  background-color: transparent !important;
  z-index: 5;
  font-size: x-large;
}

.removeCouponImg {
  left: 310px;
  top: -8px;
  position: absolute;
  border: 0px !important;
  background-color: transparent !important;
  z-index: 5;
}

.imgList {
  width: 250px;
  height: 250px;
  margin-bottom: 15px;
}

.imgBanner {
  width: 100%;
  height: 400px;
}

.viewBannerList {
  width: 100%;
  height: 100%;
}

.viewBannerListNo {
  left: 20px;
  top: 5px;
  color: black;
  font-weight: bold;
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  background-color: transparent !important;
  z-index: 5;
}

.imgListRemove {
  left: 285px;
  top: -5px;
  position: absolute;
  border: 0px !important;
  background-color: transparent !important;
  z-index: 5;
}

.imgNo {
  right: 272px;
  top: 5px;
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  background-color: transparent !important;
  z-index: 5;
}

.ViewImgNo {
  left: 15px;
  top: 4px;
  color: black;
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  background-color: transparent !important;
  z-index: 5;
}

.ViewBannerNo {
  left: 17px;
  top: 5px;
  color: black;
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  background-color: transparent !important;
  z-index: 5;
}

.disclaimer {
  position: absolute;
  font-size: 14px !important;
}

.uploadImg {
  display: none;
}

.uploadBtn {
  margin-top: 10px;
  background-color: #51bcda;
}

.submitBtn {
  background-color: #51bcda;
  width: 500px;
}

.multiDrop {
  width: 550px;
}

.clearBtn {
  background-color: red;
  margin-bottom: 0px;
}

.errorText {
  color: red;
}

.tableDesc {
  width: 250px;
  height: 250px !important;
  background-color: transparent !important;
  color: white !important;
  border: 1px solid transparent !important;
}

.btn-outline-filter {
  border: 2px solid #51cbce;
  color: white;
  background-color: transparent;
}

.btn-outline-filter.selected {
  border: 2px solid #51cbce;
  color: white;
  background-color: darkcyan;
}

.react-datepicker__input-container {
  height: 100%;
}

.searchBarPromo {
  width: 500px;
  height: 38px;
  color: black !important;
}

.searchBarPromo::placeholder {
  color: black !important;
}

.textColor {
  width: 500px;
  height: 35px;
}

.Xbtn {
  background-color: transparent;
  height: 25px;
  margin: 0;
}

.textColor::placeholder {
  color: black;
}

.delBtn {
  width: 150px;
}

.editBtn {
  width: 105px;
  height: 40px;
  border: transparent 1px solid;
}

.graphContainer {
  border: 1px solid transparent;
  padding: 20px;
}

.graphBox {
  border: 1px solid white;
  padding: 20px;
}

.graphSplit {
  border: 1px solid white;
  background-color: #e8e9eb;
  color: black;
  padding: 20px;
  margin-bottom: 15px;
}

.graphSelected {
  background-color: rgb(179, 178, 178);
}
