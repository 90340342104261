.orderBtn {
  background-color: #51bcda !important;
}

.orderBtn:hover {
  background-color: #2ba9cd !important;
}

.success {
  background-color: green !important;
}

.success:hover {
  background-color: rgb(0, 87, 0) !important;
}

.failed {
  background-color: red !important;
}

.failed:hover {
  background-color: rgb(175, 4, 4) !important;
}

.searchBar {
  color: white !important;
}

.searchBar::placeholder {
  color: white !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dateFilter {
  width: 200px;
}

@media only screen and (max-width: 768px) {
  .dateFilter {
    width: 125px;
  }
}

.tab-switch {
  padding: 5px;
  color: white;
  border: solid 1px white;
}

.tab-switch.active {
  background-color: green;
  border: solid 1px green;
}

.alt-tab {
  margin-top: 12px;
  padding: 7px;
  color: white;
  border: solid 1px white;
}

.alt-tab.active {
  background-color: #51bcda;
  border: solid 1px #51bcda;
}

.card-dash {
  height: 160px;
}

.filterMonth {
  width: 160px;
  color: white !important;
}

.filterUser {
  width: 250px;
  color: white !important;
}

.cardSplit {
  border: 1px solid white;
}

.userSearch {
  margin-top: 15px;
  width: 100%;
  height: 2.8rem;
  background: white;
  outline: none;
  font-size: 1rem;
}

.search-container {
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}

#searchBox {
  color: white;
}

.sc-fqkvVR {
  fill: white !important;
}

.sc-fqkvVR > svg {
  fill: white !important;
}

.sc-fqkvVR > input {
  color: white !important;
}

.sc-fqkvVR > input::placeholder {
  color: rgb(199, 197, 197) !important;
}

.sc-gEvEer .selected {
  background-color: #343a40 !important;
}

.sc-dcJsrY > .wrapper {
  height: auto !important;
  border: 1px solid transparent !important;
  border-radius: 5px !important;
  background-color: #5e606e !important;
  color: white !important;
}
