.dateDisplay {
  font-size: 20px;
  margin-bottom: 5px;
}

.tab-switch.active {
  background-color: #51bcda;
  border: solid 1px #51bcda;
}

.tab-switch {
  padding: 7px;
  border: solid 1px white;
}

.scrollBar {
  overflow: auto;
  /* position: absolute; */
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 0;
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
}

.submit {
  background-color: #51bcda;
  width: 200px;
}

.edit {
  background-color: #51bcda;
  width: 100px;
}

.del {
  background-color: red;
  width: 100px;
}
