.nav-underline {
  gap: 1rem;
  transition: ease-in-out 0.5s;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid transparent;
    font-size: large;
    cursor: pointer;
    color: lightskyblue;

    &:hover,
    &:focus {
      border-bottom-color: currentcolor;
      transition: ease-in-out 0.5s;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    transition: ease-in-out 0.5s;
    font-weight: bold;
    color: white;
    border-bottom-color: currentcolor;
  }
}

.dropOrder {
  .dropdown-item:hover {
    background-color: white !important;
    color: black !important;
  }
  .dropdown-item:focus {
    background-color: white !important;
    color: black !important;
  }
}
