.orderId {
  opacity: 0.48;
}

.productText {
  font-size: 21px;
  font-weight: 600;
}

.productTextSm {
  font-size: 15px;
}

.uploadBtn {
  width: 90px !important;
  height: 90px !important;
  border: 2px solid grey !important;
  border-radius: 0.4rem !important;
  border-style: dashed !important;
  background-color: transparent !important;
}

.uploadBtn:hover {
  background-color: #f5f0e5 !important;
}

.imgPreview {
  width: 90px !important;
  height: 90px !important;
  border-radius: 0.4rem !important;
  border: 2px solid grey !important;
  display: inline-block;
  position: relative;
  bottom: 5px;
}

.VimgCenter {
  margin-top: 3px;
  margin-left: 3px;
}

@media only screen and (min-width: 760px) and (max-width: 768px) {
  .orderContainer {
    max-width: 90vw;
  }
}

@media only screen and (max-width: 430px) {
  .orderContainer {
    max-width: 90vw;
  }
}

.removeImgI {
  right: -12px;
  top: -12px;
  position: absolute;
  border: 0px !important;
  background-color: transparent !important;
  z-index: 5;
}

.removeIconI {
  background-color: white;
  color: red;
}

.msgText {
  position: relative;
  display: inline;
  border-radius: 0.4rem !important;
}

.textBottom {
  font-size: 14px;
  opacity: 0.8;
  color: black;
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.hrLine {
  border: 1px solid black;
}
